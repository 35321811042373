/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In collaboration with the Toronto Biennial of Art’s ", React.createElement(_components.a, {
    href: "https://torontobiennial.org/mobile-arts-curriculum/"
  }, "Mobile Arts Curriculum"), ", ", React.createElement(_components.em, null, "Your Tkaron:to Companion Guide"), " is a series of three booklets exploring place- and arts- based explorations of the complex and contested ways the city of Toronto was established. Each Companion Guide is a passport of compiled activities that takes you from the Mississauga Waterfront to the stretch of Humber River to High Park, allowing you to become an investigator and researcher into Indigenous geographies and how colonialism has impacted both human and non-human connection to the land."), "\n", React.createElement("img", {
    className: "toom bird",
    src: "/tooms/tooms-bird.png"
  }), "\n", React.createElement(_components.h2, null, "Downloads"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://torontobiennial.org/wp-content/uploads/2022/04/Your-Tkaronto-Companion-Guide-Editorial-Sheet-Print.pdf"
  }, "Editorial Sheet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://torontobiennial.org/wp-content/uploads/2022/04/Your-Tkaronto-Companion-Guide-Humber-River-Print.pdf"
  }, "Booklet 1: Day Trip to Humber River")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://torontobiennial.org/wp-content/uploads/2022/04/Your-Tkaronto-Companion-Guide-High-Park-Print.pdf"
  }, "Booklet 2: Day Trip to High Park")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://torontobiennial.org/wp-content/uploads/2022/04/Your-Tkaronto-Companion-Guide-Waterfront-Print.pdf"
  }, "Booklet 3: Day Trip: The Waterfront Boundaries of the 1787 Toronto Purchase (Treaty 13)")), "\n"), "\n", React.createElement("img", {
    className: "toom target",
    src: "/tooms/tooms-target.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
